import { useRef, useState } from "react";
import Slider from "react-slick";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import recommendImage from "../assets/images/Recommendations/Recommendations.webp";
import recommendMapImage from "../assets/images/Recommendations/RecommendationsMap.webp";
import Button from "../components/Button";
import Image from "../components/Image";
import PageContainer from "../components/PageContainer";
import InfoCard from "../components/Recommendations/InfoCard";
import {
  RECOMMENDATION_CATEGORIES,
  RECOMMENDATIONS,
  RECOMMENDATIONS_MAP,
  RECOMMENDATIONS_TITLES,
} from "../data/recommendations";
import { useLanguageContext } from "../context/language-context";

const Recommendations = () => {
  const { translate } = useLanguageContext();
  const [tabIndex, setTabIndex] = useState<RECOMMENDATION_CATEGORIES>(
    RECOMMENDATION_CATEGORIES.Restaurants,
  );
  const sliderRef = useRef<Slider | null>(null);

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: false,
    arrows: false, // Keep navigation simple
  };

  const handleTabSelect = (index: number) => {
    const keys = Object.keys(RECOMMENDATION_CATEGORIES);
    setTabIndex(RECOMMENDATION_CATEGORIES[keys[index]]);
  };

  const handleButtonPress = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <PageContainer page="things-to-do">
      <div className="mb-4 flex flex-col space-y-8">
        {/* Increased width and color section */}
        <div className="border-gray-200 mx-auto flex w-full max-w-6xl flex-col items-center border bg-white px-4 py-8 text-center text-black">
          <h1 className="text-3xl xl:text-4xl">
            {translate("recommendations")}
          </h1>
          <p className="text-gray-700 mt-4 max-w-4xl">
            {translate("recommendations-text")}
          </p>
        </div>

        <div className="mx-auto flex w-full max-w-6xl flex-col items-center">
          {/* Add extra space below "Recommendations Map" */}
          <div className="pb-8 pt-8 text-center">
            <h1 className="text-3xl text-black xl:text-4xl">
              {translate("recommendations-map")}
            </h1>
          </div>
          <div className="mx-auto flex w-full max-w-6xl flex-col justify-evenly xl:h-[32rem] xl:flex-row">
            <div className="w-full xl:w-1/2">
              <Image
                src={recommendMapImage}
                alt="Google Map image with markers showing points of interest"
                classes="xl:h-[32rem] w-full"
              />
            </div>
            <div className="bg-gray-100 flex flex-col items-center justify-center px-4 py-8 text-center xl:w-1/3">
              <p className="text-gray-800 mb-4">
                {translate("recommendations-map-desc")}
                <br />
                <br />
                {translate("recommendations-map-desc-two")}
              </p>
              <a
                href={RECOMMENDATIONS_MAP}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-700 font-bold hover:underline"
              >
                {translate("recommendations-open-map")}
              </a>
              <p className="text-gray-600 mt-4">
                {translate("recommendations-map-list-desc")}
              </p>
            </div>
            <div className="w-full xl:w-1/2">
              <Image
                src={recommendImage}
                alt="Cat and Beto holding hands in front of iron door"
                classes="xl:h-[32rem] w-full"
              />
            </div>
          </div>
        </div>

        {/* Tabs and Carousel Section - Black & White Styling */}
        <div className="mx-auto flex w-full max-w-6xl flex-col space-y-4 text-black xl:w-3/4 xl:space-y-0">
          <div className="bg-gray-100 border-gray-300 rounded-lg border px-6 py-8 xl:px-12">
            <h3 className="text-gray-700 mx-auto mb-8 text-center text-xl">
              {translate("recommendations-text")}
            </h3>

            <Tabs
              onSelect={(index) => handleTabSelect(index)}
              className="react-tabs"
            >
              {/* Custom Tab Spacing and Layout */}
              <TabList className="mb-4 flex flex-wrap items-center justify-center gap-2 md:gap-4">
                {Object.entries(RECOMMENDATIONS_TITLES).map(([key, value]) => {
                  return (
                    <Tab
                      key={key}
                      className={`border-gray-300 text-gray-700 hover:bg-gray-200 m-0 flex h-[40px] cursor-pointer items-center justify-center border px-4 py-2 leading-none transition-all duration-300 ease-in-out ${
                        tabIndex === RECOMMENDATION_CATEGORIES[key]
                          ? "bg-gray-100 h-[50px] border-black text-black shadow-lg"
                          : ""
                      }`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      selectedClassName="border-black text-black"
                    >
                      {value}
                    </Tab>
                  );
                })}
              </TabList>

              <div className="flex flex-col lg:flex-row">
                {/* Buttons - Only visible on larger screens */}
                <div className="hidden flex-col space-y-2 lg:flex lg:w-1/4">
                  {RECOMMENDATIONS[tabIndex].map((item, index) => (
                    <Button
                      key={item.name}
                      classes="p-2 text-black bg-white border border-gray-300 hover:bg-gray-100"
                      onClick={() => handleButtonPress(index)}
                    >
                      {item.name}
                    </Button>
                  ))}
                </div>

                {/* Slider Section */}
                <div className="border-gray-300 rounded-lg border bg-white p-8 lg:m-4 lg:w-3/4">
                  {Object.keys(RECOMMENDATIONS).map((key, index) => {
                    return (
                      <TabPanel key={index}>
                        <Slider
                          key={`main-slider-${index}`}
                          ref={(slider) => (sliderRef.current = slider)}
                          {...slickSettings}
                        >
                          {RECOMMENDATIONS[key].map((item) => (
                            <InfoCard key={item.name} data={item} />
                          ))}
                        </Slider>
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Recommendations;
