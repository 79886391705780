import Image from "../Image";

interface Props {
  // title: string;
  data: {
    name: string;
    url: string;
    image: string;
    cost: string;
    note: string;
    serviceOption: string;
    area: string;
    address: string;
    rating: number;
  };
}

const InfoCard = ({ data }: Props) => {
  let titleDisplay = <h4>{data.name}</h4>;

  if (data.url) {
    titleDisplay = (
      <a href={data.url} target="_blank" rel="noopener noreferrer">
        {data.name}
      </a>
    );
  }

  return (
    <div className="flex flex-col items-center justify-between pb-8 xl:h-[34rem] xl:pb-0">
      <Image
        src={data.image}
        alt="Image related to point of interest"
        classes="aspect-square" // TODO: Height??
      />
      <div className="mt-4 flex w-full flex-col items-center">
        <div className="w-11/12 overflow-auto rounded-md border-2 bg-linen p-4 px-8 text-black shadow-inner">
          <div className="flex items-center justify-center pb-4">
            <h2 className="text-center">{titleDisplay}</h2>
            {data.cost && (
              <p className="pl-3 text-2xl font-bold">{data.cost}</p>
            )}
          </div>
          <p className="mb-4">{data.note}</p>
          <ul className="list-inside list-disc">
            {data.serviceOption && (
              <li className="list-item">
                <b>Service Option:</b> {data.serviceOption}
              </li>
            )}
            {data.area && (
              <li className="list-item">
                <b>Area:</b> {data.area}
              </li>
            )}
            {data.address && (
              <li className="list-item">
                <b>Address:</b> {data.address}
              </li>
            )}
            {data.rating && (
              <li className="list-item">
                <b>Google Rating:</b> {data.rating}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
